import React from 'react';

const BannerImage = (props) => {
    return(
        <div className="my-24">
            <img src={ props.src } alt={ props.title } />
        </div>
    )
}

export default BannerImage;