import React from 'react';

const Title = (props) => {

    return (
        <div className="pt-0 md:pt-96 mb-24 md:mb-32">
            <div className="size-hero-case flex items-center h-screen md:block">
                <h1 className="text-6xl md:text-8xl leading-tight font-semibold md:pb-40"
                    data-aos="fade-down"
                    style={{ color: props.titleColor }}
                    dangerouslySetInnerHTML={{ __html: props.title }}
                />
            </div>

            <div className="text-4xl w-full md:text-7xl leading-tight md:leading-tight md:max-w-screen-md mb-8 font-semibold md:font-normal"
                dangerouslySetInnerHTML={{ __html: props.description }} />
            <div className="mt-4 md:mt-auto mb-4 text-xl text-right">{props.smallDescription}</div>
        </div>
    )
}

export default Title;