import React from "react";

const SimplePhrase = ({ children, paddingX = 10 }) => {

    return (
        <div className={`md:text-center mx-auto my-40 md:my-60 sm:px-0 md:px-${paddingX}`}>
            <p 
                data-aos="fade-left" 
                data-aos-mirror="false" 
                className="text-2xl font-semibold md:text-4xl leading-tight md:leading-tight"
            >
                {children}
            </p>
        </div>
    )
}

export default SimplePhrase;
