import React from "react";

const ChromaticPalette = ({ children, description }) => {
    return (
        <div className="px-0 md:px-24">
            <p className="text-2xl font-semibold">Paleta cromática</p>

            {children}

            <p className="text-2xl">{description}</p>
        </div>
    )
};

export default ChromaticPalette;
