import React from 'react';

import LinkTransition from './linkTransition';
import IconArrow from '../assets/icoArrowRightYellow.inline.svg';

const NextCase = (props) => {

    return (

        <div className="container">

            <p className="text-yellow pt-24 md:pt-20 text-xl mb-24 md:mb-10 border-t border-gray-500">
                <LinkTransition to={props.link} className="flex content-center">
                    <span className="text-yellow mr-2">Próximo case</span>
                    <IconArrow className="ml-6 block md:hidden" />
                </LinkTransition>
            </p>

            <div className="flex flex-col md:flex-row border-b border-gray-500 pb-24 md:pb-20">
                
                <div className="mb-10 md:mb-0 pr-10">
                    <p className="text-xl font-regular mb-4">
                        <LinkTransition to={props.link}>
                            {props.title}
                        </LinkTransition>
                    </p>
                    <div className="text-3xl font-semibold md:leading-normal">
                        {props.description}
                    </div>
                </div>

                <figure>
                    <LinkTransition to={props.link}>
                        <img src={props.image} alt={props.title} />
                    </LinkTransition>
                </figure>

            </div>
        </div>
    );
};

export default NextCase;
