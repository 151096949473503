import React from 'react';
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

import { ThemeContext } from '../../context/theme-context';

import Layout from '../../components/layout';
import Footer from '../../components/footer';
import Title from '../../components/title';
import BannerImage from '../../components/bannerImage';
import ContactBlock from '../../components/contactBlock';
import NextCase from '../../components/nextCase';
import IntroBlock from '../../components/introBlock';
import BannerResult from '../../components/bannerResult';
import SimplePhrase from '../../components/simplePhrase';
import ChromaticPalette from '../../components/chromaticPalette';

const CasesPage = ({ location }) => {

    return (
      <Layout bg="white" location={location}>
        <Helmet>
          <script src={withPrefix("wrap.js")} type="text/javascript" />
          <title>ATUO - Cases - Code and Soul</title>
        </Helmet>

        <div className="container mx-auto">
          <Title
            title="Code <br /> and Soul"
            titleColor="#00B01E"
            description="O universo geek em uma marca que une tecnologia e criatividade"
            smallDescription="[criativa, inovadora, tech]"
          />

          <BannerImage
            src={`/codeandsoul/codeandsoul_content_one.jpg`}
            title="Code and Soul"
          />

          <IntroBlock
            logo={`/codeandsoul/codeandsoul_logo.svg`}
            alt="Code and Soul"
            description="Empresa nascida com a missão de criar soluções digitais para seus clientes e reduzir o time-to-market, além de gerar economia. O desafio era criar um posicionamento para o aumento de visibilidade e credibilidade da marca no setor de tecnologia, exaltando seu potencial de criar soluções humanizadas."
            list={[
              "Estratégia & Inovacão",
              "Pesquisa & Inteligência",
              "Design de Experiência do Consumidor",
              "Cultura, Conteúdo & Social",
            ]}
          />

          <SimplePhrase paddingX={24}>
            Desenvolver soluções criativas e conectadas com base em experiências
            mais humanas.
          </SimplePhrase>

          <div className="flex justify-center gap-y-24 md:px-24 items-center my-40 md:my-60 flex-col md:flex-row">
            <div className="md:w-1/2 text-2xl">
              <p>
                O nome Code and Soul significa código e alma, e remete à paixão
                de desenvolver soluções mais humanas, que ofereçam experiências
                surpreendentes aos usuários. A fonte foi inspirada no movimento
                old school dos gamers.
              </p>
            </div>

            <div className="md:w-1/2 text-center">
              <img
                src={`/codeandsoul/codeandsoul_stamp.svg`}
                alt="Code and Soul"
                className="inline"
              />
            </div>
          </div>

          <ChromaticPalette
            description={
              "A inspiração para as cores da marca veio do universo geek. O verde simboliza a tecnologia e os códigos, além de transmitir equilíbrio e positividade. O preto traz poder, sofisticação e neutralidade. Quando composto com outras cores, o verde cria ainda a sensação de iluminação. É a combinação de background e fontes na codificação."
            }
          >
            <div className="py-24 md:py-16 flex flex-wrap gap-y-10 gap-x-9 md:justify-between">
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-mirror="false"
                style={{ backgroundColor: "#000000" }}
              >
                #000000
              </div>
              <div
                className="cardColorBlock"
                data-aos-mirror="false"
                data-aos="fade-up"
                data-aos-delay="300"
                style={{ backgroundColor: "#00b01e" }}
              >
                #00b01e
              </div>
              <div
                className="cardColorBlock"
                data-aos-mirror="false"
                data-aos="fade-up"
                data-aos-delay="600"
                style={{ backgroundColor: "#9dfc00", color: "#000" }}
              >
                #9dfc00
              </div>
              <div
                className="cardColorBlock"
                data-aos-mirror="false"
                data-aos="fade-up"
                data-aos-delay="900"
                style={{ backgroundColor: "#cfff00", color: "#000" }}
              >
                #cfff00
              </div>
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-mirror="false"
                data-aos-delay="1200"
                style={{ backgroundColor: "#f2f2f2", color: "#000" }}
              >
                #f2f2f2
              </div>
            </div>
          </ChromaticPalette>

          <div className="grid grid-cols-1 md:grid-cols-2 my-40 gap-x-32">
            <div className="w-full md:w-3/4">
              <p className="text-2xl md:text-4xl mb-10 font-semibold md:leading-tight">
                Criar experiências fantásticas baseadas em pesquisas, análise de
                dados e conteúdo focado no usuário.
              </p>

              <p className="text-xl md:text-2xl">
                A Code and Soul é especializada em UX I UI Design, Web, Mobile,
                IoT, Aplicações Smart, Soluções em Nuvem e serviços customizados
                para projetos de seus clientes. Por isso, precisava de uma
                identidade séria, que gerasse confiança, mas sem perder a
                essência tech.
              </p>
            </div>

            <div className="mt-16 md:mt-0 px-6 text-center flex justify-center items-center">
              <img
                src={`/codeandsoul/codeandsoul_content_two.jpg`}
                alt="Code and Soul"
                className="inline"
              />
            </div>
          </div>

          <SimplePhrase paddingX={60}>
            Profissionais apaixonados por tecnologia criativa
          </SimplePhrase>

          <div className="flex flex-col md:flex-row items-stretch mb-10 gap-y-10 md:gap-0">
            <div>
              <img
                src={`/codeandsoul/codeandsoul_content_three.jpg`}
                alt="Code and Soul"
              />
            </div>

            <div className="flex flex-col md:flex-col gap-y-10 md:gap-0">
              <img
                src={`/codeandsoul/codeandsoul_content_four.jpg`}
                alt="Code and Soul"
                className="h-full"
              />
              <img
                src={`/codeandsoul/codeandsoul_content_five.jpg`}
                alt="Code and Soul"
                className="h-full"
              />
            </div>
          </div>
          <p className="text-right text-xl">
            [encontrar seu público diferenciado com mídias inusitadas]
          </p>

          <SimplePhrase paddingX={40}>
            Soluções inteligentes e adaptáveis conforme a necessidade do usuário
          </SimplePhrase>

          <div className="flex flex-col items-center mt-40 mb-32 md:my-40">
            <div className="w-full md:w-3/4">
              <img
                src={`/codeandsoul/codeandsoul_content_six.png`}
                alt="Code and Soul"
              />
            </div>
          </div>

          <div className="mt-40 mb-40 md:my-60 md:text-center">
            <h3 className="mx-auto text-2xl md:text-4xl font-semibold mb-10 md:px-36">
              A marca ganhou propósito, presença digital e capacidade de gerar
              experiência inovadora para seus clientes.
            </h3>
            <p className="text-xl md:px-24">
              Uma marca com nome forte e significado. Cores que representam com
              autoridade o segmento em que atua. Posicionamento claro que
              transmite a tecnologia, a inovação e a paixão em desenvolver o
              futuro focado no ser humano.
            </p>
          </div>
        </div>

        <BannerResult
          bg="#00B01E"
          list={[
            "Experiência de marca",
            "Marca com propósito",
            "+ Presença digital",
            "Posicionamento claro",
          ]}
        />

        <NextCase
          link="/cases/cervogia"
          title="Cervogia"
          description="Uma marca rica em detalhes, cultura e tradição."
          image="/cervogia-next.png"
        />

        <ThemeContext.Consumer>
          {(cx) => (
            <ContactBlock
              description="Gostou dos nossos cases? Tem muito mais de onde veio! Clique no botão ao lado e entre em contato conosco. Vamos dar o primeiro passo para sua marca ir além."
              weather={cx?.cookies?.weather}
            />
          )}
        </ThemeContext.Consumer>

        <Footer />
      </Layout>
    );
};

export default CasesPage;
