import React from 'react';

const BannerResult = (props) => {
    return (
      <div className="mb-40" style={{ backgroundColor: props.bg }}>
        <div className="container mx-auto flex flex-col md:grid grid-cols-2 text-white py-24 md:py-40">
          <p className="text-xl mb-10 md:text-3xl font-light">
            Nossos resultados
          </p>
          <ul>
            {props.list.map((item, index) => (
              <li
                key={index}
                data-aos="fade-right"
                data-aos-delay={index * 300}
                className="text-2xl mb-7 md:text-4xl md:mb-14"
                data-aos-mirror="false"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
}

export default BannerResult;