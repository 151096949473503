import React from 'react';

const IntroBlock = (props) => {

    return(
        <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-40">
            <div className="order-2 md:order-1 mt-40 md:my-0 flex justify-center items-center">
                <img src={ props.logo } alt={ props.alt } className="inline" />
            </div>

            <div className="order-1 md:order-2 sm:px-0 md:px-20">
                <p className="text-2xl font-semibold mb-10 md:mb-4">Desafio</p>
                <div className="text-xl" dangerouslySetInnerHTML={{ __html: props.description }} />

                <p className="text-2xl font-semibold mt-24 md:mt-20 mb-10 md:mb-4">Serviços</p>
                <ul className="list-items">
                    { props.list.map((item, index) => <li key={index} className="flex gap-2 mb-4"><span>–</span><p className="text-2xl">{item}</p></li>)}
                </ul>
            </div>
        </div>
    )
}

export default IntroBlock;